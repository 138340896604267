import * as Sentry from '@sentry/nextjs';

export const initSentry = (): void => {
    if (process.env.NODE_ENV === 'production') {
        Sentry.init({
            dsn: 'https://0d4c5b4b544d9a006fb12443d1ee047a@o167748.ingest.us.sentry.io/4506823368572928',
            tracesSampleRate: 0,
        });
    }
};

export { Sentry };
